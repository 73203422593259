














import {Component, Vue} from 'vue-property-decorator';
import {createPreset, Preset} from '@/types';
import {PresetService} from '@/api';
import UTabs from '@/ui-lib/tabs/Tabs.vue';
import UTab from '@/ui-lib/tabs/Tab.vue';

@Component<PresetItemView>({
    components: {
        UTabs,
        UTab,
    },

    async beforeRouteEnter(to, from, next) {
        try {
            const presetId = parseInt(to.params.id, 10);
            const preset = await PresetService.item(presetId);

            next((vm) => {
                vm.preset = preset;
            });
        } catch (e) {
            next(e);
        }
    },

    metaInfo() {
        return {
            titleTemplate: `${this.name} - %s`,
        };
    },
})
export default class PresetItemView extends Vue {
    private preset: Preset = createPreset();

    private get name() {
        return `Пресет: ${this.preset.name}`;
    }
}
